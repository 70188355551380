import "core-js/modules/es.array.push.js";
//引入js
import router from '@/router/index';
import { createToken } from '@/api/user';
export default {
  components: {},
  data() {
    return {
      baseUrl: process.env.VUE_APP_BASE_API
    };
  },
  created: async function () {
    const ajaxData = {};
    ajaxData.id = '56571aaa20ab4c2988d9861972fbac0f';
    const resultData = await createToken(ajaxData);
    if (resultData.code == 200) {
      // 准备过期时间
      let expireTime = Date.parse(new Date()) / 1000 + 72000; //20小时后过期
      // 同步更新store
      this.$store.commit('setIdentity', 1);
      this.$store.commit('setToken', resultData.data);
      this.$store.commit('setExpireTime', expireTime);
      // 存储到localStorage
      localStorage.setItem('identity', 1);
      localStorage.setItem('token', resultData.data);
      localStorage.setItem('expireTime', expireTime);
      router.push({
        path: '/wechatuser/home'
      });
    }
  },
  beforeMount: function () {},
  mounted: function () {},
  methods: {}
};